<template>
  <div class="account-review">
    <en-table-layout
      :tableData="tableData.data"
      :loading="loading"
      @selection-change="handleSelectionChange"
    >
      <template slot="toolbar">
        <el-form-item label="类型">
          <el-select
            size="small"
            style="width: 100px"
            v-model="advancedForm.out_way"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            size="small"
            style="width: 90px"
            v-model="advancedForm.pass"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="申请时间">
          <el-date-picker
            style="width: 130px"
            size="small"
            v-model="create_time1"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-input v-model.trim="keyWrapper[params.searchKey]" size="small" placeholder="请输入关键字进行搜索" clearable>
            <el-select style="width: 100px;" v-model="params.searchKey"  @change="keyWrapper={}" slot="prepend">
              <el-option label="企业名称" value="shop_name"></el-option>
              <el-option label="企业ID" value="shop_id"></el-option>
            </el-select>
          </el-input>
          <!-- <el-input
            size="small"
            placeholder="企业名称"
            style="width: 180px"
            v-model="advancedForm.shop_name"
            clearable
          ></el-input> -->
        </el-form-item>

        <el-button
          @click="GET_AccountList"
          type="primary"
          size="small"
        >
          搜索
        </el-button>

        <div class="col"></div>
        <!-- <el-button @click="handleGoodsAudit(idsList, true)" size="small" type="primary">批量审核</el-button> -->

        <el-button
          @click="handleBatchDelete()"
          size="small"
          type="primary"
        >
          批量删除
        </el-button>

        <el-button
          @click="handleExport"
          type="primary"
          size="small"
        >
          导出数据
        </el-button>
      </template>
      <template slot="table-columns">
        <el-table-column
          type="selection"
          width="40"
          fixed="left"
        ></el-table-column>

        <el-table-column
          prop="shop_name"
          label="企业名称"
          width="250"
          fixed="left"
        />

        <el-table-column
          prop="balance_account"
          label="账户余额"
          width="100"
          fixed="left"
        >
          <template slot-scope="scope">
            {{ scope.row.balance_account | unitPrice("￥") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="out_way_cash_money"
          label="申请内容"
          :formatter="out_way_cash_money"
          width="200"
        ></el-table-column>

        <el-table-column label="申请时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>

        <!--类型-->
        <el-table-column
          prop="out_way_name"
          label="类型"
          :formatter="out_way"
          width="150"
        ></el-table-column>

        <el-table-column
          prop="pass"
          label="状态"
        >
          <template slot-scope="scope">
            {{ scope.row.pass | passFilter(that) }}
          </template>
        </el-table-column>

        <!--操作-->
        <el-table-column
          label="操作"
          width="210"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="scope.row.pass === 0"
              @click="handleAudit(scope.row)"
            >
              审核
            </el-button>

            <el-button
              size="mini"
              type="primary"
              @click="getDetail(scope.row)"
            >
              查看
            </el-button>

            <el-button
              size="mini"
              type="danger"
              @click="handleDelete([scope.row.id])"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="MixinPageSizes"
        :page-size="tableData.page_size"
        :layout="MixinTableLayout"
        :total="tableData.data_total"
        background
      ></el-pagination>
    </en-table-layout>

    <!--审核商品 dialog-->
    <el-dialog-x-dialog
      :title="dialogGoodsAuditTitle"
      :visible.sync="dialogGoodsAuditVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="goodsAuditForm"
        :rules="goodsAuditRules"
        ref="goodsAuditForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          label="申请企业"
          prop="shop_name"
          style="margin-bottom: 0"
        >
          <span>{{ goodsAuditForm_info.shop_name }}</span>
        </el-form-item>
        <el-form-item
          label="账户余额"
          prop="balance_account"
          style="margin-bottom: 0"
        >
          <span>{{
            goodsAuditForm_info.balance_account | unitPrice("￥")
          }}</span>
        </el-form-item>
        <el-form-item
          label="提现/充值金额"
          prop="cash_money"
          style="margin-bottom: 0"
          label-width="102px"
        >
          <span>{{
            goodsAuditForm_info.cash_money | unitPrice("￥")
          }}(手续费在提现金额中扣除)</span>
        </el-form-item>
        <el-form-item
          label="手续费"
          prop="fee_money"
          style="margin-bottom: 0"
        >
          <span>{{ goodsAuditForm_info.fee_money | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item
          label="是否通过"
          prop="pass"
          style="margin-bottom: 0"
        >
          <el-radio-group v-model="goodsAuditForm.pass">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="充值截图"
          prop="pass"
            v-if="goodsAuditForm_info.img_url"
        >
          <img
            :src="goodsAuditForm_info.img_url"
            @click="handleImgLook(goodsAuditForm_info.img_url)"
            alt
            class="pass_img"
          />
          <!-- <el-button type="primary" size="small" @click="handleImgLook(goodsAuditForm_info.img_url)">查看大图</el-button> -->
        </el-form-item>
        <el-form-item
          label="备注信息"
          prop="comment"
          style="margin-bottom: 0"
        >
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4 }"
            placeholder="请输入审核备注(120字以内)"
            :maxlength="120"
            v-model="goodsAuditForm.comment"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="text-align:right;"
        >
          <el-button
            @click="dialogGoodsAuditVisible = false"
            size="small"
          >取 消</el-button>
          <el-button
            type="primary"
            @click="submitGoodsAuditForm"
            size="small"
          >确 定</el-button>
        </el-form-item>
      </el-form>

    </el-dialog-x-dialog>
    <!-- 查看dialog -->
    <el-dialog-x-dialog
      :title="detailTitle"
      :visible.sync="dialogGoodspass"
      width="400px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="goodsAuditForm"
        :rules="goodsAuditRules"
        ref="goodsAuditForm"
        label-width="100px"
      >
        <!--是否通过=-->
        <el-form-item
          label="申请企业"
          prop="shop_name"
        >
          <span>{{ goodsAuditForm.shop_name }}</span>
        </el-form-item>
        <el-form-item
          label="账户余额"
          prop="balance_account"
        >
          <span>{{ goodsAuditForm.balance_account | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item
          label="提现/充值金额"
          prop="cash_money"
        >
          <span>{{ goodsAuditForm.cash_money | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item
          label="审核状态"
          prop="pass"
        >
          <span>{{ goodsAuditForm.pass | passFilter(that) }}</span>
        </el-form-item>
        <el-form-item
          label="备注"
          prop="comment"
        >
          <span>{{ goodsAuditForm.comment || "无" }}</span>
        </el-form-item>
        <el-form-item
          label="充值截图"
          prop="pass"
          v-if="goodsAuditForm.img_url"
        >
          <img
            :src="goodsAuditForm.img_url"
            @click="handleImgLook(goodsAuditForm.img_url)"
            alt
            class="pass_img"
          />
          <!-- <el-button type="primary" size="small" @click="handleImgLook(goodsAuditForm.img_url)">查看大图</el-button> -->
        </el-form-item>
      </el-form>
      <!-- <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="dialogGoodspass = false"
          size="small"
        >取 消</el-button>
            <el-button type="primary" @click="submitGoodsAuditForm" size="small">确 定</el-button>
      </span> -->
    </el-dialog-x-dialog>
    <!-- 查看大图 -->
    <el-dialog
      :visible.sync="centerDialogVisible"
      modal
      close-on-click-modal
      custom-class="dialog"
    >
      <el-carousel
        :autoplay="false"
        arrow="never"
      >
        <el-carousel-item
          v-for="item in centerDialogList"
          :key="item"
        >
          <img
            :src="item"
            style="width: 100%; height: 100%"
          />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
export default {
  name: "goodsAudit",
  components: { EnTableLayout },
  data() {
    return {
      that: this,
      options1: [
        {
          name: "全部",
          value: "-1",
        },
        {
          name: "提现",
          value: "1",
        },
        {
          name: "线下充值",
          value: "0",
        },
        {
          name: "微信充值",
          value: "4",
        },
      ],
      options2: [
        {
          name: "全部",
          value: "-1",
        },
        {
          name: "待审核",
          value: "0",
        },
        {
          name: "已通过",
          value: "1",
        },
        {
          name: "已拒绝",
          value: "2",
        },
      ],
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        searchKey: 'shop_name'
      },
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        comment: "",
        pass: 1,
      },
      goodsAuditForm_info: {},
      // 审核商品 表单校验
      goodsAuditRules: {
        comment: [
          { required: false, message: "请输入审核备注！", trigger: "blur" },
        ],
      },
      // 审核商品 dialog
      dialogGoodsAuditVisible: false,
      // 审核商品 dialogTitle
      dialogGoodsAuditTitle: "审核商品",
      // 高级搜索数据
      advancedForm: {
        account_type: "3", //固定为 3：企业
        // 类型
        out_way: "-1",
        // 状态
        pass: "-1",
        // 企业名称
        shop_name: "",
        // 审核时间
        create_time: "",
      },
      detailTitle: "查看",
      create_time1: "",
      //查看商品变更记录
      dialogGoodspass: false,
      tableDatapass: [],
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已通过",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      centerDialogVisible: false, // 显示查看器
      centerDialogList: [],
      keyWrapper: {}
    };
  },
  mounted() {
    this.GET_AccountList();
  },
  activated() {
    this.GET_AccountList();
  },
  filters: {
    passFilter(val, that) {
      return that.options.find(function (item) {
        return item.value === val;
      }).label;
    },
  },
  watch: {
    "goodsAuditForm.pass": function (newVal) {
      this.goodsAuditRules.comment[0].required = newVal === 4;
    },
  },
  methods: {
    handleImgLook(url) {
      console.log(url);
      this.centerDialogList = [url];
      // this.showViewer = true;
      this.centerDialogVisible = true;
    },
    /** 申请内容格式化 scope.row.out_way+scope.row.cash_money*/
    out_way_cash_money(row, column, cellValue) {
      const { out_way, cash_money } = row;
      if (out_way === 0) {
        return (row.out_way_cash_money = "线下充值" + cash_money + "元");
      } else if (out_way === 1) {
        return (row.out_way_cash_money = "转至会员账户" + cash_money + "元");
      } else if (out_way === 2) {
        return (row.out_way_cash_money = "转至支付宝" + cash_money + "元");
      } else if (out_way === 3) {
        return (row.out_way_cash_money = "转至银行卡" + cash_money + "元");
      } else if (out_way === 4) {
        return (row.out_way_cash_money = "微信充值" + cash_money + "元")
      }
    },
    /** 类型格式化 scope.row.out_way+scope.row.cash_money*/
    out_way(row, column, cellValue) {
      const { out_way } = row;
      if (out_way === 0) {
        return (row.out_way_name = "线下充值");
      } else if (out_way === 1) {
        return (row.out_way_name = "提现(转至会员账户)");
      } else if (out_way === 2) {
        return (row.out_way_name = "提现(转至支付宝)");
      } else if (out_way === 3) {
        return (row.out_way_name = "提现(转至银行卡)");
      } else if (out_way === 4) {
        return (row.out_way_name = '微信充值')
      }
    },
    // 获取财务审核列表
    GET_AccountList() {
      this.loading = true;
      this.advancedForm.create_time = this.create_time1
        ? new Date(this.create_time1).getTime() / 1000
        : "";
      this.advancedForm = {
        ...this.advancedForm,
        ...this.params,
      };
      API_account.getCashOutPage({
        ...this.advancedForm,
        ...this.keyWrapper
      })
        .then((res) => {
          if (res.page_no !== 1 && res.data.length === 0) {
            this.params.page_no = res.page_no -= 1
            this.GET_AccountList()
          } else {
            this.tableData = res;
            this.loading = false;
          }
        })
        .catch(() => (this.loading = false));
    },
    //导出订单
    handleExport() {
      const { advancedForm: params } = this;
      delete params.page_no;
      delete params.page_size;
      API_account.exportCashOutPage(params).then((response) => {
        const json = {
          sheet_name: "账户变动列表",
          sheet_values: response.map((item) => ({
            企业名称: item.shop_name,
            账户余额: item.balance_account,
            申请内容:
              item.out_way == 0
                ? "线下充值"
                : item.out_way == 1
                ? "提现(转至会员账户)"
                : item.out_way == 2
                ? "提现(转至支付宝)"
                : item.out_way == 3
                ? "提现(转至银行卡)"
                : "微信充值" + item.cash_money,
            申请时间: Foundation.unixToDate(item.create_time),
            类型:
              item.out_way == 0
                ? "线下充值"
                : item.out_way == 1
                ? "提现(转至会员账户)"
                : item.out_way == 2
                ? "提现(转至支付宝)"
                : item.out_way == 3
                ? "提现(转至银行卡)"
                : "微信充值",
            状态:
              item.pass == 0
                ? "待审核"
                : item.pass == 1
                ? "审核通过"
                : "审核不通过",
          })),
        };
        this.MixinExportJosnToExcel(json, "账户变动列表");
      });
    },
    /** 删除 */
    handleDelete(id) {
      this.$confirm("确定要删除吗？", "提示", { type: "warning" })
        .then(() => {
          API_account.deleteCashOutPage({ ids: id }).then((res) => {
            this.$message.success("删除成功");
            this.GET_AccountList();
          });
        })
        .catch(() => {});
    },
    /** 批量删除 */
    handleBatchDelete() {
      if (this.idsList.length) {
        this.$confirm("确定要删除吗？", "提示", { type: "warning" })
          .then(() => {
            API_account.deleteCashOutPage({ ids: this.idsList }).then((res) => {
              this.$message.success("删除成功");
              this.GET_AccountList();
            });
          })
          .catch(() => {});
      } else {
        this.$message.error("请选择要删除的数据");
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.GET_AccountList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params.keyword;
      this.GET_AccountList();
    },

    /** 审核 */
    handleAudit(row) {
      console.log(row)
      this.dialogGoodsAuditTitle = "审核";
      let ids = [];
      ids.push(row.id);
      this.idsList = ids;
      API_account.getCashOut(row.id).then((response) => {
        response.balance_account = row.balance_account;
        this.goodsAuditForm_info = response;
      });
      this.goodsAuditForm = {
        ids: ids,
        comment: "",
        pass: 2,
        out_way: row.out_way === 0 || row.out_way === 4 ? 1 : ''
      };
      this.goodsAuditForm_info = {
        ...this.goodsAuditForm_info,
        ...row,
      };

      this.dialogGoodsAuditVisible = true;
    },
    /** 查看 */
    getDetail(row) {
      this.detailTitle = "查看";
      API_account.getCashOut(row.id).then((response) => {
        response.balance_account = row.balance_account;
        this.goodsAuditForm = response;
      });
      this.goodsAuditForm = {
        ...this.goodsAuditForm,
        ...row,
      };
      this.dialogGoodspass = true;
    },

    /** 审核 表单提交 */
    submitGoodsAuditForm() {
      this.$refs["goodsAuditForm"].validate((valid) => {
        if (valid) {
          API_account.getCashOutAudit(this.idsList, this.goodsAuditForm).then(
            (response) => {
              this.dialogGoodsAuditVisible = false;
              this.$message.success("审核完成！");
              this.GET_AccountList();
            }
          );
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.pass_img {
  width: 90%;
  height: 160px;
  cursor: pointer;
}
</style>
