/**
 * 商品管理API
 */

import request from '@/utils/request'

/**
 * 企业手动充值
 * @param params
 * @returns {Promise<any>}
 */
export function addCompanyNew(params) {
	return request({
		url: 'admin/shops/shopBalanceLog/addCompanyNew',
		// headers: { 'Content-Type': 'application/json','token':token },
		method: 'post',
		loading: false,
		params
	})
}

/**
 * 获取企业验证码
 * @param shop_id
 * @returns {Promise<any>}
 */
export function smscode(data) {
	return request({
		url: 'admin/shops/shopBalanceLog/smscode',
		method: 'post',
		loading: false,
		data
	})
}

/**
 * 获取订单结算
 * @param params
 * @returns {Promise<any>}
 */
export function getSettleOrderPage(params) {
	return request({
		url: 'admin/checkaccount/settleOrderPage',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 获取财务企业手动充值列表
 * @param params
 * @returns {Promise<any>}
 */
export function adminShops(params) {
	return request({
		url: '/admin/shops',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 获取充值记录
 * @param params
 * @returns {Promise<any>}
 */
export function getRechargeRecord(params) {
  return request({
    url: 'admin/shops/shopBalanceLog/companyBalanceLogList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 获取订单结算导出
 * @param params
 * @returns {Promise<any>}
 */
export function getSettleOrderList(params) {
	return request({
		url: 'admin/checkaccount/settleOrderList',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 添加对账表
 * @param params
 * @returns {Promise<any>}
 */
export function checkaccountAdd(data) {
	return request({
		url: '/admin/checkaccount/add',
		method: 'post',
		data,
		// headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 搜索对账表
 * @param params
 * @returns {Promise<any>}
 */
 export function checkaccountCaList(params) {
 	return request({
 		url: '/admin/checkaccount/ca-list',
 		method: 'get',
 		loading: false,
 		params
 	})
 }

 /**
  * 搜索对账表
  * @param params
  * @returns {Promise<any>}
  */
  export function checkaccountCaPage(params) {
  	return request({
  		url: '/admin/checkaccount/ca-page',
  		method: 'get',
  		loading: false,
  		params
  	})
  }

  /**
   * 审核对账表
   * @param params
   * @returns {Promise<any>}
   */
  export function checkaccountEdit(id, params) {
  	return request({
  		url: `/admin/checkaccount/${id}`,
  		method: 'post',
  		data: params,
  		// headers: { 'Content-Type': 'application/json' }
  	})
  }


  /**
   * 搜索对账表内订单
   * @param params
   * @returns {Promise<any>}
   */
   export function checkaccountPageCaid(params) {
   	return request({
   		url: '/admin/checkaccount/page-caid',
   		method: 'get',
   		loading: false,
   		params
   	})
   }





/**
 * 获取店铺商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodsList(params) {
	return request({
		url: '/admin/shopGoods',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 选择供应商商品
 * @param params
 * @returns {Promise<any>}
 */
export function selectShopGoods(params) {
	return request({
		url: '/admin/shopGoods',
		method: 'post',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}



/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteGoods(ids, params) {
	const _params = {}
	return request({
		url: `admin/goods/${ids}/recycle`,
		method: 'put',
		data: _params
	})
}

/**
 * 搜索库存商品数据  搜索商品sku（规格）信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsStockList(ids, params) {
	return request({
		url: `admin/goods/${ids}/skus`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 保存库存商品
 * @param goods_id
 * @param params
 * @returns {Promise<any>}
 */
export function reserveStockGoods(goods_id, params) {
	return request({
		url: `admin/goods/${goods_id}/quantity`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 发布商品   搜索商品参数，获取所选分类关联的参数信息
 * @param category_id
 * @returns {Promise<any>}
 */
export function getGoodsParams(category_id) {
	return request({
		url: `admin/goods/category/${category_id}/params`,
		method: 'get',
		loading: false
	})
}

/**
 * 编辑商品   搜索商品参数，获取所选分类关联的参数信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getEditGoodsParams(category_id, goods_id) {
	return request({
		url: `admin/goods/category/${category_id}/${goods_id}/params`,
		method: 'get',
		loading: false
	})
}

/**
 * 草稿箱编辑 搜索草稿箱商品参数
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsDraftParams(ids, params) {
	return request({
		url: `admin/goods/draft-goods/${ids}/params`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索商品品类信息  ids 为category_id 商城商品品类
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCatrgory(ids, params) {
	return request({
		url: `admin/goods/${ids}/skus`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodData(ids, params) {
	return request({
		url: `admin/goods/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getShopGoodData(ids, params) {
	return request({
		url: `admin/shopGoods/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}


/**
 * 搜索单个草稿箱商品信息 草稿箱商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodDraftData(ids, params) {
	return request({
		url: `admin/goods/draft-goods/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 品牌列表 ids为categoryid  商品发布，获取所选分类关联的品牌信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsBrandList(ids, params) {
	return request({
		url: `admin/goods/category/${ids}/brands`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 运费模板列表
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getTplList(ids, params) {
	return request({
		url: `admin/shops/ship-templates`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 获取积分商品分类列表
 * @param id
 */
export function getExchangeCatsList(id) {
	return request({
		url: `admin/promotion/exchange-cats/${id}/children`,
		method: 'get',
		loading: false
	})
}

/**
 * 商家下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function underGoods(ids, params) {
	return request({
		url: `admin/goods/${ids}/under`,
		method: 'put',
		data: params
	})
}

/**
 * 商家列表下架商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function changeStatus(ids, params) {
	return request({
		url: `/admin/shopGoods/${ids}/changeStatus`,
		method: 'put',
		data: params
	})
}




/**
 * 上架正常商品 商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveGoods(params) {
	return request({
		url: 'admin/goods',
		method: 'post',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editGoods(id, params) {
	return request({
		url: `admin/goods/${id}`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 修改店铺商品价格
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function revisePrice(id, params) {
	return request({
		url: `/admin/shopGoods/${id}/revisePrice`,
		method: 'put',
		data: params,
		// headers: { 'Content-Type': 'application/json' }
	})
}


/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editShopGoods(id, params) {
	return request({
		url: `admin/shopGoods/${id}`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 正常商品 保存草稿 草稿商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function saveDraft(params) {
	return request({
		url: `admin/goods/draft-goods`,
		method: 'post',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 上架草稿箱商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveDraftGoods(ids, params) {
	return request({
		url: `admin/goods/draft-goods/${ids}/market`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/** 草稿箱商品 保存草稿 即 修改草稿箱商品 */
export function editDraftGoods(id, params) {
	return request({
		url: `admin/goods/draft-goods/${id}`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 搜索草稿箱sku信息
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function draftSku(id, params) {
	return request({
		url: `admin/goods/draft-goods/${id}/skus`,
		method: 'get',
		data: params
	})
}

/**
 * 获取草稿箱商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getDraftGoodsList(params) {
	return request({
		url: 'admin/goods/draft-goods',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 删除草稿箱商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteDraftGoods(ids, params) {
	const _params = {}
	return request({
		url: `admin/goods/draft-goods/${ids}`,
		method: 'delete',
		data: _params
	})
}

/**
 * 获取回收站商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getRecycleGoodsList(params) {
	return request({
		url: 'admin/goods',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 回收站 还原商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleReductionGoods(ids, params) {
	return request({
		url: `admin/goods/${ids}/revert`,
		method: 'put',
		data: params
	})
}

/**
 * 回收站 彻底删除商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function RecycleDeleteGoods(ids, params) {
	return request({
		url: `admin/goods/${ids}`,
		method: 'delete',
		data: params
	})
}

/**
 * 获取预警商品列表
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsList(params) {
	return request({
		url: 'admin/goods/warning',
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 查看预警商品库存信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getWarningGoodsStockList(ids, params) {
	return request({
		url: `admin/goods/${ids}/skus`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 获取分销设置
 */
export function getDistributionSet() {
	return request({
		url: 'admin/distribution/setting',
		method: 'get',
		loading: false
	})
}

/**
 * 获取分销返利信息
 * @param id
 */
export function getDistributionInfo(id) {
	return request({
		url: `admin/distribution/goods/${id}`,
		method: 'get',
		loading: false
	})
}

/**
 * 保存分销返利
 * @param params
 */
export function setDistributionInfo(params) {
	return request({
		url: 'admin/distribution/goods',
		method: 'put',
		data: params
	})
}

/**
 * 账户变动审核
 * @param params
 */
export function getCashOutPage(data) {
	return request({
		url: 'account/cashOut/page',
		method: 'post',
		data
	})
}

/**
 * 账户变动删除
 * @param params
 */
export function deleteCashOutPage(data) {
	return request({
		url: 'account/cashOut/delete',
		method: 'post',
		data
	})
}

/**
 * 账户变动导出
 * @param params
 */
export function exportCashOutPage(data) {
	return request({
		url: 'account/cashOut/list',
		method: 'post',
		data
	})
}

/**
 * 短信签名审核列表
 * @param params
 */
export function getShopsSmsSignList(params) {
	return request({
		url: 'admin/shops/smsSign',
		method: 'get',
		 params
	})
}

/*
   添加短信签名表
*/
export function addManage(params) {
	return request({
		url: '/admin/shops/smsSign',
		method: 'post',
		data: params
	})
}


/**
 * 短信签名审核+批量审核
 * @param params
 */
export function getShopsSmsSignAuditSign(sign_ids,params) {
	return request({
		url:`admin/shops/smsSign/auditSign/${sign_ids}`,
		method: 'put',
		data:params
	})
}


/**
 * 修改账户提现管理，用于大平台审核/批量审核操作
 * @param params
 */
export function getCashOutAudit(ids,params) {
	return request({
		url:`account/cashOut/audit`,
		method: 'post',
		data:params
	})
}

/**
 * 搜索账户提现管理详情
 * @param params
 */
export function getCashOut(id) {
	return request({
		url:`account/cashOut/${id}`,
		method: 'get'
	})
}

/**
 *  查看供应商预付款充值记录
 * @param params
 */
export function getShopBalanceLog(params) {
	return request({
		url: `/admin/shops/shopBalanceLog/page`,
		method: 'get',
		params
	})
}

/**
 *  查看供应商预付款充值记录
 * @param params
 */
export function addShopBalanceLog(params) {
	return request({
		url: `/admin/shops/shopBalanceLog/add`,
		method: 'post',
		params
	})
}
